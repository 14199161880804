import React from 'react';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
function App() {
  return (
    <Router>
      <div className="container mx-auto max-w-screen-lg">
        <header className="py-4" style={{ marginBottom: '-80px', height: '80px' }}>
            <Link to="/">
              <h1 className="text-4xl font-bold text-left text-sky-500">
              TikTyp
              </h1>
            </Link>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
