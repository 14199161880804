import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="container mx-auto mt-20">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <div className="prose max-w-none">
        <p>Effective date: 2024-07-20</p>
        <p>Last updated: 2024-07-20</p>
        <br/>
        <p>Thanks for using TikTyp ('TikTyp', 'we', 'us', 'our'). We take your privacy seriously, and this Privacy Policy describes how we handle your information.</p>

        <p>This Privacy Policy applies to our website tiktyp.com and all services we provide (collectively, the 'Services'). By using the Services, you are accepting the terms and conditions of this Privacy Policy and our Terms of Service.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Table of Contents</h2>
        <ul className="list-disc pl-6">
          <li><a href="#data-collection">What data do we collect?</a></li>
          <li><a href="#data-usage">How do we use your data?</a></li>
          <li><a href="#data-storage">How do we store your data?</a></li>
          <li><a href="#data-protection-rights">What are your data protection rights?</a></li>
          <li><a href="#log-data">What log data do we collect?</a></li>
          <li><a href="#cookies">Cookies</a></li>
          <li><a href="#other-websites">Privacy policies of other websites</a></li>
          <li><a href="#policy-changes">Changes to our privacy policy</a></li>
          <li><a href="#contact">How to contact us</a></li>
        </ul>

        <h2 id="data-collection" className="text-2xl font-semibold mt-6 mb-4">What data do we collect?</h2>
        <p>TikTyp does not collect or store any personal data. All functionality of our website operates client-side in your browser.</p>

        <h2 id="data-usage" className="text-2xl font-semibold mt-6 mb-4">How do we use your data?</h2>
        <p>As we don't collect any personal data, we do not use your data for any purpose.</p>

        <h2 id="data-storage" className="text-2xl font-semibold mt-6 mb-4">How do we store your data?</h2>
        <p>TikTyp does not store any of your personal data on our servers. Any data generated during your use of the website (such as typing test results or settings) is stored locally in your browser and is not transmitted to us.</p>

        <h2 id="data-protection-rights" className="text-2xl font-semibold mt-6 mb-4">What are your data protection rights?</h2>
        <p>As we don't collect or store any personal data, there is no personal data for us to provide access to, rectify, erase, restrict processing of, object to processing of, or transfer.</p>

        <h2 id="log-data" className="text-2xl font-semibold mt-6 mb-4">What log data do we collect?</h2>
        <p>Like most websites, TikTyp may collect standard log information that your browser sends when you visit our website. This data may include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and time spent on each page. This data does not contain any personally identifiable information and is used for analyzing trends and administering the site.</p>

        <h2 id="cookies" className="text-2xl font-semibold mt-6 mb-4">Cookies</h2>
        <p>TikTyp uses only essential cookies necessary for the website to function properly. These cookies do not collect any personal information and are used solely to enhance your browsing experience (e.g., remembering your preferences).</p>

        <h2 id="other-websites" className="text-2xl font-semibold mt-6 mb-4">Privacy policies of other websites</h2>
        <p>The TikTyp website may contain links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>

        <h2 id="policy-changes" className="text-2xl font-semibold mt-6 mb-4">Changes to our privacy policy</h2>
        <p>TikTyp keeps its privacy policy under regular review and places any updates on this web page. This privacy policy may be subject to change at any given time without notice.</p>

        <h2 id="contact" className="text-2xl font-semibold mt-6 mb-4">How to contact us</h2>
        <p>If you have any questions about TikTyp's privacy policy, please contact us at:</p>
        <p>Email: <a href="mailto:contact@tiktyp.com">contact@tiktyp.com</a></p>
      </div>
      <div className="mt-8">
        <Link to="/" className="text-blue-500 hover:text-blue-700">Back to Home</Link>
      </div>
    </div>
  );
}

export default PrivacyPolicy;