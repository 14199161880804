import { Icon } from "@iconify/react";
import { Button, Card, CardBody, CardFooter, Chip, Divider } from "@nextui-org/react";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PracticeProvider, usePractice } from '../contexts/PracticeContext';

const TextDisplay = React.memo(() => {
  const { text, userInput, cursorPosition, setCursorPosition, isFinished, cursorPositionReplay, retry, material, inputRef } = usePractice();
  const activeChar = useRef(null);
  const initialCursorPosition = useMemo(() => ({
    left: "-10px",
    top: 0
  }), []);
  const [cursorActive, setCursorActive] = useState(false);
  
  useEffect(() => {
    if (userInput.length === 0) {
      setCursorActive(false);
      setCursorPosition(initialCursorPosition); 
    } else if (activeChar.current) {
      setCursorActive(true);
      const newPos = {
        left: activeChar.current.offsetLeft + activeChar.current.offsetWidth,
        top: activeChar.current.offsetTop - 2
      };
      setCursorPosition(newPos);
    }
  }, [userInput, initialCursorPosition, setCursorPosition]);

  return (
    <div className={`text-display text ${isFinished ? 'finished' : ''}`} onClick={ () => inputRef.current?.focus()}>
      <p className='text-4xl text-gray-500'>
        {retry && <span className="cursor text-gray-500 retry" style={!cursorPositionReplay ? initialCursorPosition : cursorPositionReplay}></span>}
        <span className={`cursor text-sky-500 ${cursorActive ? '' : 'not-active'}`} 
        style={cursorPosition}></span>
      {text.split('').map((char, index) => {
        const cn = [
          char === ' ' ? 'space' : '',
          index < userInput.length ? (char === userInput[index] ? 'text-teal-500' : 'text-rose-500') : ''
        ].filter(Boolean);
        return (
          <span
            key={index}
            ref={index === userInput.length - 1 ? activeChar : null}
            className={cn.join(' ')}
          >
            {char}
          </span>
        );
      })}
      </p>
      <p className="text-gray-500 text-md mt-5 italic indent-5">- {material.author}</p>
    </div>
  );
});

const InputArea = React.memo(({ inputRef, isFinished, onKeyDown }) => (
  <div 
    className="input-area" 
    autoFocus 
    ref={inputRef} 
    tabIndex="0" 
    onKeyDown={onKeyDown}
    style={{ 
      position: 'absolute', 
      opacity: 0,
      top: 0,
      left: 0,
      cursor: 'text'
    }}
  >
    {!isFinished && <span className="cursor" style={{ display: 'none' }}>|</span>}
  </div>
));
const BannerFooterResults = ({show, adkey, height, width}) => {
  const [isAdsterraLoaded, setIsAdsterraLoaded] = useState(false);
  const banner = useRef(null);
  const atOptions = useMemo(() => {
    return {
      key: adkey,
      format: 'iframe',
      height: height,
      width : width,
      params : {}
    };
  }, [adkey, height, width]);

  useEffect(() => {
    if (!show) return;
    if (banner.current && !banner.current.firstChild) {
      const conf = document.createElement('script');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
      script.onerror = () => {
        console.error('Failed to load Adsterra script');
      };
      script.onload = () => {
        console.log('Adsterra script loaded successfully');
        setIsAdsterraLoaded(true);
      };
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
      banner.current.append(conf);
      banner.current.append(script);
    }
  }, [banner, atOptions, show]);

  return (
    <div className={`${show && isAdsterraLoaded ? 'visible' : 'hidden'} bg-gray-500`} style={{height: height, width: width}} ref={banner}/>
  );
}
const Results = React.memo(({ wordCount, accuracy, isFinished, onNext, doRetry }) => {
  return (
    <div className={`results mx-auto ${isFinished ? 'visible' : 'hidden'}`}>
      <Card>
        <CardBody>
          <div className="flex flex-col">
            <div className="flex justify-center space-x-4 mb-2">
              <Chip startContent={<Icon icon="mdi:speedometer" className="text-sky-500"/>} variant="bordered">Speed</Chip>
              <Chip startContent={<Icon icon="streamline:target-solid" className="text-orange-500"/>} variant="bordered">Accuracy</Chip>
            </div>
            <div className="flex justify-center space-x-4">
              <span className="flex items-center justify-center w-1/2 text-sm">{wordCount} WPM</span>
              <span className="flex items-center justify-center w-1/2 text-sm">{accuracy}%</span>
            </div>
          </div>
        </CardBody>
        <Divider />
        <CardFooter>
          <div className="flex justify-center w-full">
            <Button size="sm" variant="light" startContent={<Icon icon="mdi:reload" />} onClick={doRetry}>Retry</Button>
            <Button size="sm" variant="light" startContent={<Icon icon="ant-design:forward-outlined" />} onClick={onNext}>Next</Button>
          </div>
        </CardFooter>
      </Card>
      <div className="text-xs mt-10 mb-10"
      > <Chip variant="solid" radius="sm" size="sm">r</Chip> - retry test<span className="mr-5"/><Chip variant="solid" radius="sm" size="sm">enter</Chip> - next test
      </div>
    </div>
  ) ;
});


function PracticeView({onFinished}) {
  const {
    text,
    isFinished,
    handleKeyPress,
    calculateAccuracy,
    wordCount,
    doRetry,
    inputRef,
    reset,
  } = usePractice();
  const container = useRef(null);

  useEffect(() => {
    if (text !== ".." && inputRef.current && !inputRef.current.contains(document.activeElement)) {
      inputRef.current.focus();
    }
  }, [text, inputRef]);
  
  return (
    <div className="container flex relative items-center justify-center">
      <TextDisplay/>
      <InputArea 
        inputRef={inputRef}
        isFinished={isFinished}
        onKeyDown={handleKeyPress}
      />
      <div className="flex flex-col items-center absolute ">
        <div className="mt-40">
          <Results 
            container={container}
            wordCount={wordCount}
            accuracy={calculateAccuracy()}
            isFinished={isFinished}
            onNext={ () => reset()}
            doRetry={doRetry}
          />
        </div>
        <BannerFooterResults show={false} adkey="d930d48923ac20ae8881dd763b453043" height={90} width={728}/>
      </div>
    </div>
  );
}
function Home() {
  return (
    <PracticeProvider>
      <div className="flex flex-col items-center justify-center h-screen relative">
        <PracticeView/>
        <div className="absolute bottom-4 w-full flex justify-center">
          <Link to="/privacy-policy" className="text-blue-500 hover:text-blue-700 transition-colors duration-300">Privacy Policy</Link>
        </div>
      </div>
      
    </PracticeProvider>
  );
}

export default Home;
